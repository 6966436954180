import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import FormCustomer from '@/Models/formCustomer'
@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class FormCustomerStep2 extends Vue {
  step2: FormCustomer = new FormCustomer()
  @Prop({ required: false }) objStep2!: any
  @Prop({ required: false, default: null })
  countrySelect!: string
  country = ''

  mounted() {
    this.country = this.countrySelect
    if (this.objStep2) {
      this.step2 = this.objStep2
    }
  }

  @Watch('countrySelect')
  onModal(value: string, oldValue: string) {
    this.country = this.countrySelect
  }
  async validateFields() {
    const isValid = await (this.$refs['observer'] as any).validate()
    if (isValid) {
      return true
    } else {
      return false
    }
  }
  fileBirth_certificate() {
    this.$emit('birth_certificate_upload')
  }
  fileMarriage_certificate() {
    this.$emit('marriage_certificate_upload')
  }
  fileFbi_criminal_history() {
    this.$emit('fbi_criminal_history_upload')
  }
  filePassport_copies() {
    this.$emit('passport_copies_upload')
  }
  filePension_certificate() {
    this.$emit('pension_certificate_upload')
  }
  filePassport_size() {
    this.$emit('passport_size_picture_upload')
  }
}
