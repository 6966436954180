export default class FormCustomer {
  id!: number
  first_name!: string
  middle_name!: string
  surname!: string
  maiden_name!: string
  full_name!: string
  marital_status!: string
  many_times_divorced!: number
  passport_number!: string
  date_issuance_passport!: Date
  date_expiry_passport!: Date
  country_origin!: string
  phone_number!: number
  email!: string
  address_cr!: string
  residency_type!: string
  single_application!: string
  many_dependents!: number
  birth_certificate!: File
  marriage_certificate!: File
  fbi_criminal_history!: File
  passport_copies!: File
  pension_certificate!: File
  passport_size_picture!: File
  appointment_office!: Date
}
